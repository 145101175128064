<template>
    <div class="paper">
        <template v-if="show_temp == 0">
            <div class="paper-bar">
                <div class="paper-bar__content">
                    <span @click="confirmSave">交 卷</span>
                    <span @click="gotoXuexi">返 回</span>
                </div>
            </div>
            <div class="paper-answer">
                <div class="count-down">
                    <span class="count-info">
                        <i class="el-icon-alarm-clock"></i>
                        考试倒计时
                    </span>
                    <div class="count-num">
                        <span class="count-num__time">{{ hour }}</span><span>:</span>
                        <span class="count-num__time">{{ minutes }}</span><span>:</span>
                        <span class="count-num__time">{{ seconds }}</span>
                    </div>
                    <div class="count-text">
                        <span>Hours</span>
                        <span>Minutes</span>
                        <span>Seconds</span>
                    </div>
                </div>
                <div class="answer-sheet">
                    <p class="answer-sheet__title">答题卡</p>
                    <div class="answer-sheet__content">
                        <template v-for="(item, index) in examList">
                            <p class="answer-sheet__content--header" :key="index" v-if="item.level === 1">
                                {{ item.title }}
                            </p>
                            <div class="answer-sheet__content--body" :key="index"
                                v-if="item.level == 2 || item.level == 3">
                                <p class="sheet-title">
                                    {{ item.title }}
                                </p>
                                <div class="sheet-item">
                                    <span :class="[timu_item.is_answer == 1 ? 'item-made' : '']"
                                        v-for="(timu_item, timu_index) in item.timu" :key="timu_index">{{
                                                timu_item.num
                                        }}</span>
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="answer-sheet__footer">
                        <div v-for="(item, index) in items" :key="index">
                            <span></span>
                            <span>{{ item }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="paper-wrapper">
                <div class="paper-wrapper__title">
                    <h3>{{ shijuanInfo.particular_year }}</h3>
                    <h3>{{ shijuanInfo.title }}</h3>
                    <h3>{{ shijuanInfo.deputy_title }}</h3>
                    <span>（考试时间 {{ shijuanInfo.exam_time }} 分钟，满分 {{ shijuanInfo.score }} 分）</span>
                </div>
                <template v-for="(item, index) in examList">
                    <div class="paper-wrapper__content listen" :key="index">
                        <h3 v-if="item.level === 1">
                            {{ item.title }}
                        </h3>
                        <div class="topic">
                            <div class="topic-title" id="listen-select" v-if="item.level === 2">
                                <div class="topic-title__wrapper">
                                    <span class="topic-title__text">
                                        {{ item.title }}
                                    </span>
                                </div>
                            </div>
                            <div class="topic-content">
                                <template v-if="item.level === 3">
                                    <div :class="['topic-content__title',item.align == 0 ? 'align_left' : 'align_center']">
                                        {{ item.title }}
                                    </div>
                                </template>
                                <span class="topic-content__title" v-if="item.deputy_title">
                                    {{ item.deputy_title }}
                                </span>
                                <div class="topic-content__img" v-if="item.materials && item.is_end == 1">
                                    <template v-if="item.materials.content">
                                        <div v-html="item.materials.content"></div>
                                    </template>
                                    <div v-if="item.materials.audio != '' && item.materials.audio != null">
                                        <audio v-if="item.materials.audio != ''" id="audio" ref="audio" controls>
                                            <source :src="item.materials.audio | img">
                                            <source :src="item.materials.audio | img">
                                            您的浏览器不支持 audio 元素。
                                        </audio>
                                    </div>
                                    <ul class="img-list">
                                        <template v-for="(c_item, c_index) in item.materials.images">
                                            <li :key="c_index">
                                                <img :src="c_item.url | img" alt="" />
                                                <span>{{ c_index | getSerial }}</span>
                                            </li>
                                        </template>
                                    </ul>
                                </div>
                                <template v-if="item.timu && item.is_end == 1">
                                    <div class="topic-content__body" v-for="(g_item, g_index) in item.timu"
                                        :key="g_index">
                                        <div class="body-title" v-if="g_item.title !== '' && g_item.title !== null">
                                            <span>{{ g_item.num }}.</span>
                                            <span v-html="g_item.title"></span>
                                        </div>
                                        <template v-if="g_item.type == 1 || g_item.type == 2">
                                            <!--<span class="same-num" style="font-size: 18px;"
                                                v-if="g_item.title == '' || g_item.title == null">{{ g_item.num
                                                }}.</span>-->
                                            <ul v-for="(h_item, h_index)  in g_item.value" :key="h_index"
                                                :class="[h_item.item_arrange == 1 ? 'body-list1' : 'body-list2']">
                                                <!-- 横排或者竖排 -->
                                                <li>
                                                    <span class="list-num same-num"
                                                        v-if="g_item.type === '' || g_item.title == null">
                                                        {{ g_item.num }}.
                                                    </span>
                                                    <el-radio @change="radioChange(g_item, h_item, i_item.num)"
                                                        v-model="h_item.answer" v-for="(i_item, i_index) in h_item.item"
                                                        :key="i_index" :label="i_item.num">
                                                        <span class="list-title same-title">
                                                            {{ i_item.num | getSerial }}
                                                        </span>
                                                        <span class="list-answer same-answer">
                                                            {{ i_item.value }}
                                                        </span>
                                                        <img v-if="i_item.img != '' && i_item.img != null"
                                                            :src="i_item.img | img" alt="">
                                                    </el-radio>
                                                </li>
                                            </ul>
                                        </template>
                                        <template v-if="g_item.type == 3">
                                            <el-radio-group v-model="g_item.answer">
                                                <el-radio :label="judge_item.id"
                                                    @change="radioChangeStage(g_item, judge_item.id)"
                                                    v-for="(judge_item, judge_index) in judge_item" :key="judge_index">

                                                    <span class="list-title same-title">
                                                        {{ judge_index | getSerial }}
                                                    </span>
                                                    <span class="list-answer same-answer same-china">
                                                        {{ judge_item.name }}
                                                    </span>
                                                </el-radio>
                                            </el-radio-group>
                                        </template>
                                        <template v-if="g_item.type == 4">
                                            <div class="topic-content__body">
                                                <template v-if="g_item.title == '' || g_item.title == null">
                                                    {{ g_item.num }}.
                                                </template>
                                                <input type="text" v-model="g_item.answer" :key="index"
                                                    v-on:input="pin_input(g_item, g_item.answer,item)">
                                            </div>
                                        </template>
                                        <template v-if="g_item.type == 5">
                                            <div class="topic-content__body">
                                                <el-input class="textarea" type="textarea" v-model="g_item.answer" :rows="15"
                                                    v-on:input="pin_input(g_item, g_item.answer,item)"
                                                    placeholder="请在这里输入作文内容"></el-input>
                                            </div>
                                        </template>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </template>
                <el-row type="flex" justify="center" style="margin-top: 10px;">
                    <el-button type="primary" class="paper-save__btn" :loading="loading" @click="confirmSave">交 卷
                    </el-button>
                </el-row>
            </div>
        </template>
        <paper-result :result="result" @close="showAnalysis" v-if="show_temp == 1" />
        <paper-analysis @close="analysisClose" :kaoshi-info="kaoshiInfo" :shijuan-info="shijuanInfo"
            :exam-list="examList" :score="examScore" v-if="show_temp == 2" />
    </div>
</template>

<script>
import paperResult from "@/views/papers/paper_result"
import paperAnalysis from "@/views/papers/paper_analysis"

import { mapState } from "vuex";
let that;
let SERIAL_NUM = ["A", "B", "C", "D", "E", "F", "G"]; //选项编号
let JUDGE_ITEM = [{ id: 1, name: '正确' }, { id: 0, name: '错误' }]

export default {
    name: "test_papers",
    components: {
        paperResult,
        paperAnalysis
    },
    data() {
        return {
            id: 0,
            radio: 0,
            currentIndex: 0,
            is_visible: true,
            items: ['已答', '未做', '标记'],
            kaoshiInfo: {},
            examList: [],
            shijuanInfo: {},
            stageScoreArr: [],//分类分
            judge_item: JUDGE_ITEM,
            djs_obj: '', //倒计时对象
            djs_count: 5, //答错后倒计时5秒
            djs_state: 1, //倒计时状态
            djs_stop: 0,
            audio_obj: [],
            yongshi: "",
            yongshi_time: "",
            djs_msg: '',
            maxtime: 0,
            kaoshi_status: 1,//1考试中，0已结束
            hour: '00',
            minutes: '00',
            seconds: '00',
            loading: false,
            show_temp: 0,//显示考试，1显示结果，2查看解析
            result: {},
            errorMoldList: [],//错题
            errorTimuList: [],
        }
    },
    created() {
        this.id = this.$route.query.id;
        this.getShijuan();
    },
    filters: {
        img: (val) => {
            // msg表示要过滤的数据，a表示传入的参数
            return that.$conf.storage_url + "/" + val;
        },
        getSerial(val) {
            return SERIAL_NUM[val];
        },
    },
    computed: {
        ...mapState({
            token: (state) => state.user.token,
            user: (state) => state.user.user,
        }),
        examScore() {
            let score = 0;
            let stageScoreArr = [];
            let stageItem = { id: '', name: '', score: 0, item_score: 0 };
            let stageIndex = 0;
            let stageScore = 0;
            this.examList.forEach((item, index) => {
                if (item.pid == 0 && item.level == 1) {
                    stageItem.id = item.id;
                    stageItem.name = item.title;
                    stageItem.item_score = item.full_score;
                    stageScoreArr.push(Object.assign({}, stageItem));
                    stageIndex++;
                    //console.log('temp_id:'+ temp_id + 'id:' +id);
                }
                //console.log(stageScoreArr);
                //console.log('stageIndex:'+stageIndex);
                let is_all_right = 1;//每栏是否全对
                item.timu.forEach((timu_item, index_item) => {
                    if (timu_item.is_right == 1) {
                        console.log("right",timu_item);

                        score = score + timu_item.answer_score;
                        console.log("right_score",score);
                        //console.log('stageIndex:'+stageIndex);
                        stageScoreArr[stageIndex - 1].score = stageScoreArr[stageIndex - 1].score + timu_item.answer_score;
                    }
                    //console.log('timu_item.type:'+timu_item.type);
                    //console.log('timu_item.is_right:'+timu_item.is_right);
                    if (timu_item.type !== 5 && timu_item.is_right == 0) {
                        is_all_right = 0;
                        //console.log('is_all_right:' + is_all_right);
                    }
                })
                //console.log('is_all_right:'+is_all_right);
                if (is_all_right == 0) {
                    //this.errorMoldList.push(item.uuid);
                }
            });
            this.stageScoreArr = stageScoreArr;
            return score;
        }
    },
    beforeCreate: function () {
        that = this;
    },
    methods: {
        gotoXuexi() {
            this.$router.push({ name: "papers" });
        },
        getShijuan() {
            let data = {};
            data.token = this.token;
            data.id = this.id;
            this.axios
                .post("/api/exam/shijuan", data)
                .then((res) => {
                    //console.log(res);
                    if (res.data.status_code == 200) {
                        let data = res.data.data;
                        this.examList = data.info.shijuan_mold;
                        this.kaoshiInfo = data.kaoshiInfo;
                        this.shijuanInfo = data.info;
                        this.serializeData();
                        //开始倒计时
                        this.maxtime = this.kaoshiInfo.exam_time * 60;
                        this.setDjs();
                    } else {
                        this.$message(res.data.msg);
                    }
                })
                .catch((err) => {
                    //this.loading = false;
                });

        },
        serializeData() {
            this.examList.forEach((item, index) => {
                item.timu.forEach((timu_item, timu_index) => {
                    timu_item.is_answer = 0;//是否已做
                    timu_item.is_flag = 0;//是否标记
                });
            });

            /*let aa = [];
            aa = this.examList.map((item,index)=>{
                item.timu.map((timu_item,timu_index)=>{
                    timu_item.is_answer = 0;//是否已做
                    timu_item.is_flag = 0;//是否标记
                    return timu_item;
                });
                return item;
            });*/


            //console.log('this.examList', this.examList);
        },
        confirmSave() {
            if (this.kaoshi_status == 0) {
                return;
            }

            let that = this;
            let maxtime = that.djs_count;
            //console.log(that.djs_count);
            let minutes = Math.floor(maxtime / 60);
            let seconds = seconds = Math.floor(minutes % 60);
            this.$confirm('确定要交卷吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.saveData();
            }).catch(() => {
            });
        },
        saveData() {
            if (this.kaoshi_status == 0) {
                return;
            }
            this.loading = true;
            this.kaoshi_status = 0;
            let data = {};
            data.token = this.token;
            data.score = this.examScore;
            this.getError();
            //console.log('errorMoldList', this.errorMoldList);

            data.kaoshiInfo = this.kaoshiInfo;
            data.examList = this.examList;
            data.yongshi = this.yongshi;
            data.yongshi_time = this.yongshi_time;
            data.stageScore = this.stageScoreArr;
            data.errorMoldList = this.errorMoldList;
            data.errorTimuList = this.errorTimuList;

            this.axios.post("/api/exam/save_exam", data).then((res) => {
                if (res.data.status_code == 200) {
                    /*this.$message({
                        message: res.data.msg,
                        type: 'success'
                    });*/
                    this.loading = false;
                    this.clearDjs();
                    this.result.create_time = res.data.data.create_time;
                    //this.showResult();
                    this.showAnalysis();
                }
                else {
                    this.$message(res.data.msg);
                }
            }).catch((err) => {
                this.loading = false;
                this.kaoshi_status = 1;
            }).then(() => {
                setTimeout(() => {
                    this.kaoshi_status = 1;
                    this.loading = false;
                }, 5000)
            });

            //console.log('examList', this.examList);
        },
        radioChange(timu_item, timu_value_item, num) {

            if (timu_value_item.right_answer == num) {
                timu_value_item.is_right = 1;
                //console.log('right');
            } else {
                timu_value_item.is_right = 0;
                //console.log('error');
            }

            let is_right = 1;//是否正确
            let is_anwwer = 1;//是否已做
            for (let i = 0; i < timu_item.value.length; i++) {
                //timu_item.value[i].is_right = 0;
                if (timu_item.value[i].is_right == 0) {
                    is_right = 0;
                }
                if (timu_item.value[i].answer === '' || timu_item.value[i].answer === null) {
                    is_anwwer = 0;
                    //console.log('is_answer', is_anwwer);
                }
            }
            timu_item.is_right = is_right;
            if (timu_item.is_right == 1) {
                timu_item.answer_score = timu_item.score;
            }else{
                timu_item.answer_score = 0;
            }
            timu_item.is_answer = is_anwwer;
            //单选和选择填空
            //console.log('timu_item', timu_item);
            //console.log('timu_value_item', timu_value_item);
            //console.log('num', num);
        },
        radioChangeStage(timu_item, index) {
            //判断            
            if (timu_item.right_answer == index) {
                timu_item.is_right = 1;
                timu_item.answer_score = timu_item.score;
                //console.log('is_right', index);
            } else {
                timu_item.is_right = 0;
                timu_item.answer_score = 0;
                //console.log('error', index);
            }
            timu_item.is_answer = 1;// 标记为已做
            //console.log('timu_item', timu_item);
        },
        pin_input(g_item, answer,item = '') {
            //console.log('g_item', g_item);
            //console.log("answer", answer);
            if (answer == '' || answer == null) {
                g_item.is_answer = 0;
            } else {
                g_item.is_answer = 1;
            }

            //词汇
            if(g_item.type == 4 && item.type==6){
                if(g_item.answer == g_item.right_answer){
                    g_item.is_right = 1;
                    g_item.answer_score = g_item.score;
                }else{
                    g_item.is_right = 0;
                    g_item.answer_score = 0;
                }
            }

        },
        showResult() {
            let result = this.result;
            result.yongshi_time = this.yongshi_time;
            result.yongshi = this.yongshi;
            result.shijuanInfo = this.shijuanInfo;
            result.kaoshiInfo = this.kaoshiInfo;
            result.score = this.examScore;
            result.stageScoreArr = this.stageScoreArr;
            this.show_temp = 1;
        },
        showAnalysis() {
            this.show_temp = 2;//查看解析
        },
        /**
         * [study_djs 倒计时]
         * @param  {Number} value [description]
         * @return {[type]}       [description]
         */
        setDjs(value = 0) {
            var that = this;
            that.djs_count = that.maxtime;
            if (this.djs_obj) {
                clearInterval(this.djs_obj);
            }
            if (value > 0) {
                that.djs_count = value;
            }
            //console.log("倒计时" + that.djs_count);
            that.djs_state = 1;
            let maxtime = that.djs_count;
            this.djs_obj = setInterval(function () {
                if (that.djs_state != 1) {
                    clearInterval(that.djs_obj);
                    that.djs_msg = "";
                    return false;
                }
                if (that.djs_stop == 1) {
                    return;
                }
                //djs_count,djs_obj,djs_state,djs_msg,djs_stop,yongshi
                if (maxtime >= 0) {
                    let hour = 0;
                    //console.log(maxtime);
                    if (maxtime == 498) {
                        that.$message({
                            message: '距离考试结束还有10分钟，请抓紧时间答题！',
                            type: 'warning',
                            showClose: true,
                            duration: 10000
                        });
                    }
                    let minutes = Math.floor(maxtime / 60);
                    if (minutes > 60) {
                        hour = Math.floor(minutes / 60);
                        minutes = Math.floor(minutes % 60);
                    }
                    let seconds = Math.floor(maxtime % 60);
                    if (hour < 10) {
                        hour = "0" + hour;
                    }
                    if (minutes < 10) {
                        minutes = "0" + minutes;
                    }
                    if (seconds < 10) {
                        seconds = "0" + seconds;
                    }
                    ///that.djs_msg = hour + ":" + minutes + ":" + seconds;
                    //console.log(that.djs_msg);
                    if (that.kaoshi_status == 1) {
                        --maxtime;
                        that.djs_count = maxtime;
                        that.zj_yongshi();
                    }
                    that.hour = hour;
                    that.minutes = minutes;
                    that.seconds = seconds;
                } else {
                    clearInterval(that.djs_obj);
                    that.djs_msg = "";
                    that.kaoshi_status = 0;
                    that.saveData();
                }
            }, 1000);
        },
        zj_yongshi() {
            var that = this;
            that.yongshi++;
            var hour = Math.floor(that.yongshi / 3600);
            var minute = Math.floor((that.yongshi - hour * 3600) / 60);
            var second = that.yongshi - hour * 3600 - minute * 60;
            if (hour > 0) {
                that.yongshi_time = hour + "时" + minute + "分" + second + "秒";
            } else {
                that.yongshi_time = minute + "分" + second + "秒";
            }
            //console.log(that.yongshi_time);
        },
        clearDjs() {
            if (this.djs_obj) {
                clearInterval(this.djs_obj);
            }
        },
        changeVisible() {
            this.is_visible = !this.is_visible;
        },
        handleClick(index) {
            console.log(index)
            this.currentIndex = index;
        },
        analysisClose() {
            this.$router.push({ name: "papers" });
        },
        getError() {
            //获取我的错题
            //console.log("getError");
            this.examList.forEach((item, index) => {
                if (item.level == 2) {
                    //console.log("item.type", item.type);
                    //添加二级栏目的错题
                    let all_rigth = 1;

                    item.timu.forEach((timu_item, timu_index) => {
                        if (timu_item.type == 1 || timu_item.type == 2 || timu_item.type == 3) {
                            if (timu_item.is_right == 0) {
                                this.errorTimuList.push(timu_item.id);
                                all_rigth = 0;
                            }
                        }
                    });

                    this.examList.forEach((c_item, c_index) => {
                        if (item.id == c_item.pid) {
                            //添加子级栏目的错题
                            c_item.timu.forEach((timu_item, timu_index) => {
                                if (timu_item.type == 1 || timu_item.type == 2 || timu_item.type == 3) {
                                    if (timu_item.is_right == 0) {
                                        all_rigth = 0;
                                        this.errorTimuList.push(timu_item.id);
                                    }
                                }
                            });
                        }
                    });
                    if (all_rigth == 0) {
                        this.errorMoldList.push(item.uuid);
                    }

                }
            });
            //console.log("this.errorMoldList", this.errorMoldList);
            //console.log("this.errorTimuList", this.errorTimuList);
        }
    },
    destroyed() {
        //window.removeEventListener("keyup", this.key_up);
        //window.removeEventListener("keydown", this.key_down);
        this.clearDjs();
    }
}
</script>

<style lang="scss">
@import "~@/assets/css/test_papers.scss";

.align_left{
    text-align: left;
    display:block !important;
}
.align_center{
    text-align: center;
    display:block !important;
}
.textarea{
    font-size: 30px;
}
</style>
